import React, { useContext } from "react";
import { Script } from "gatsby";
import TrackingContext from "../../context/TrackingContext";

function OutbrainWidget( { obWidgetID, utm, obLink } ) {

    const { abtest } = useContext(TrackingContext);
    
    return (
        <>
            <Script src='//widgets.outbrain.com/outbrain.js' type="text/javascript" />
            <div className="outbrain-widget">
                <div 
                    className="OUTBRAIN" 
                    data-src={obLink} 
                    data-widget-id={obWidgetID} 
                    data-external-id={utm} 
                    data-external-secondary-id={abtest} 
                />
            </div>
        </>
    );
      
}
export default OutbrainWidget;