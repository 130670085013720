import React, { useEffect, useState } from "react";
import { graphql, Script } from "gatsby";
import loadable from "@loadable/component";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
//import parse from "html-react-parser";
import { IntlProvider, FormattedMessage } from "react-intl";

import Layout from "../components/layout";
import GoogleAds from "../components/google-ads";
import SeoStructure from "../components/seo-structure";
import { fetchHeaderBids } from "../components/header-bidding"
import ImageAds from "../components/adunits/Image-ads";
import HeaderScripts from "../components/HeadScripts";
import OutbrainWidget from "../components/vendor/OutbrainWidget";

import { isBrowser, isMobilePredicate } from "../utils";
import useTaboola from "../hooks/useTaboola";
import { defaultLanguage } from "../i18n";
import LocalesContext from "../context/LocalesContext";

export function Head() {
  return (
    <HeaderScripts />
  )
}

// Flag to insert ads just for first image

function BlogPost({ data, pageContext }) {
  
  // Loadable imports
  const Avantis = loadable(() => import("../components/vendor/Avantis"));
  const TaboolaWidget = loadable(() => import("../components/vendor/TaboolaWidget"));

  const post = data.posts.nodes[0];
  const [isMobile, setIsMobile] = useState(false);
  const [windowOrigin, setWindowOrigin] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [utm, setUtm] = useState('');

  const { setupTaboola } = useTaboola(1);
  const locale = pageContext.locale !== defaultLanguage ? `/${pageContext.locale}` : "";

  const showTaboola = isBrowser && ( utm && !(utm.includes("ob-") || utm.includes("Zemanta") || utm.includes("nosource") ) );
  const showOutbrain = isBrowser && utm && !utm.includes("nosource") && (utm.includes("ob-") || utm.includes("Zemanta"));
  const pageURL= process.env.GATSBY_MULTILINGUAL === 'true' ? `${process.env.GATSBY_SITE_URL}/${pageContext.locale}/${pageContext.slug}/` : `${process.env.GATSBY_SITE_URL}/${pageContext.slug}/`;

  let imageAdsInserted = false;
  
  useEffect(() => {

    setUtm(window.localStorage.getItem('utm_source'));

    setIsMobile(isMobilePredicate());

    setWindowOrigin(window.location.origin);

    setCurrentPage(0);
    
  }, []);

  // Setup Taboola Web Push
  useEffect(() => {
    setupTaboola();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Move ads from page to page on mobile
  function moveAd(elementID, currentPage, adContainer) {
    const adDivID = document.getElementById(elementID);
    adDivID.removeAttribute('id');
    const section = document.querySelector(`[data-index="${currentPage}"]`);
    const adDivClass = section.getElementsByClassName(adContainer);
    adDivClass[0].id = elementID;
  }

  //Set Next Page
  const handleNextPage = () => {
    setCurrentPage( currentPage + 1 );
    window.googletag.cmd.push(function() {
      let gptSlots = window.googletag.pubads().getSlots();
      gptSlots.forEach(function(adUnit) {
        adUnit.setTargeting('currentPageTC', currentPage + 2 );
      });
    });

    if(isMobile) {
      // Move Ads to next page
      moveAd("LeaderBelowTitle", currentPage + 1, "ad-container--leaderBelowTitle");
      moveAd("MPUTopRight", currentPage + 1, "ad-container--MPUTopRight");
    }
    
    fetchHeaderBids( window.apsAdUnits, window.pbjsAdUnits, 2300);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  }
  //Set Prev Page
  const handlePrevPage = () => {
    setCurrentPage( currentPage - 1 );

    window.googletag.cmd.push(function() {
      let gptSlots = window.googletag.pubads().getSlots();
      gptSlots.forEach(function(adUnit) {
        adUnit.setTargeting('currentPageTC', currentPage );
      });
    });

    if(isMobile) {
      // Move mobile Ads to previous page
      moveAd("LeaderBelowTitle", currentPage - 1, "ad-container--leaderBelowTitle");
      moveAd("MPUTopRight", currentPage - 1, "ad-container--MPUTopRight");
    }

    fetchHeaderBids( window.apsAdunits, window.pbjsAdUnits, 2300);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (_.isEmpty(post)) return null;

  const canonicalSlug = `/${pageContext.slug}`;

  return (
    <>
      <Script src={process.env.GATSBY_PUBSTACK_TAG}/>
      <GoogleAds
        type="next"
        page={pageContext.page}
        lang={pageContext.locale}
      />
      <Seo post={post} />

      <SeoStructure
        title={post.seo.title ? post.seo.title : post.title}
        description={post.seo.metaDesc ? post.seo.metaDesc : ''}
        slug={`${pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${!process.env.GATSBY_SITE_NAME ? "" : process.env.GATSBY_SITE_NAME}`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
      <article className="post">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post.author.node.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          {!isMobile && (
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container"
              />
            </div>
          )}

          <Avantis mobileId={process.env.GATSBY_AVANTIS_NEXT_TAGID} desktopId={process.env.GATSBY_AVANTIS_NEXT_TAGID} />

          <div className="post-content">
            {post.nextPageTC.map((article, index) => {
              const dataURL = `${windowOrigin}${locale}/${pageContext.slug}/${index + 1 === 1 ? "" : index + 1}`;
              return (
                <ImageAds
                  key={index}
                  index={index}
                  isMobile={isMobile}
                  dataURL= {dataURL}
                  imageAdsInserted={imageAdsInserted}
                  currentPage= {currentPage}
                  article= {article}
                />
              )
            })}
          </div>

          <div className="code-block-next">
            
          <div className="code-block code-block--MPU">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="MPUCenterRight" className="ad-container"/>
            </div>
            
            {showTaboola && isMobile && (
              <TaboolaWidget
                mode="thumbnails-c"
                container="taboola-above-paging-buttons"
                placement="Widget Above Next"
                utm={utm}
              />
            )}

            {showOutbrain && isMobile &&
              <OutbrainWidget 
                obWidgetID="CR_1"
                utm={utm}
                obLink={pageURL}
              />
            }

            <div className="paggination-buttons">
              <ul className="paggination-buttons__wrapper">
                {currentPage !== 0 && (
                  <li className="prev">
                    <button
                      onClick={() => {
                        handlePrevPage()
                        if (window.assertive) {
                          window.assertive.nextPage();
                        }
                      }}
                    >
                      ⟵
                    </button>
                  </li>
                )}
                {currentPage !== post.nextPageTC.length && (
                <li className="next">
                  <button
                    onClick={() => {
                      handleNextPage()
                      if (window.assertive) {
                        window.assertive.nextPage();
                      }
                    }}
                  >
                    <FormattedMessage id="next" defaultMessage="Next" />
                  </button>
                </li>
                )}
              </ul>
            </div>

  
            <div className="code-block code-block--MPU">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="MPUBelowNext" className="ad-container"/>
            </div>
          </div>

          {showTaboola && !isMobile && 
            <TaboolaWidget
              mode="thumbnails-b"
              container="taboola-below-article"
              placement="Widget Below Next"
              utm={utm}
            />
          }

          {showOutbrain && !isMobile &&
            <OutbrainWidget 
              obWidgetID="AR_1"
              utm={utm}
              obLink={pageURL}
            />
          }

          <div className="code-block code-block--footer">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div id="StickyBanner" className="ad-container"/>
          </div>
        </div>

      
        <aside className="post-ads-sidebar col-1">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="LeftColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>

        <aside className="post-ads-sidebar col-2">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>
      </article>
    </>
  );
}

const BlogPosts = ({ data, pageContext }) => {
  const avaiablePostTranslations = data.avaiablePostTranslations.nodes;
  const avaiableLangs = avaiablePostTranslations.map(
    (post) => post.language.slug
  );

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.slug,
          avaiableLangs,
          pageNumber: pageContext.page,
        }}
      >
        <Layout containerClasses="container--left">
          <BlogPost data={data} pageContext={pageContext} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!) {
    avaiablePostTranslations: allWpPost(
      filter: { slug: { eq: $slug } }
    ) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        content
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        seo {
          title
          metaDesc
          focuskw
          metaRobotsNoindex
          metaRobotsNofollow
          metaKeywords
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
            height
            width
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`;
